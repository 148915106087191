define("discourse/plugins/discourse-ai/initializers/ai-helper", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-ai/discourse/components/modal/diff-modal"], function (_exports, _pluginApi, _diffModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeProofread(api) {
    api.addComposerToolbarPopupMenuOption({
      action: toolbarEvent => {
        const modal = api.container.lookup("service:modal");
        modal.show(_diffModal.default, {
          model: {
            toolbarEvent
          }
        });
      },
      icon: "spell-check",
      label: "discourse_ai.ai_helper.context_menu.proofread_prompt",
      shortcut: "ALT+P",
      condition: () => {
        const siteSettings = api.container.lookup("service:site-settings");
        const currentUser = api.getCurrentUser();
        return siteSettings.ai_helper_enabled && currentUser?.can_use_assistant_in_post;
      }
    });
  }
  var _default = _exports.default = {
    name: "discourse-ai-helper",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.1.0", api => {
        initializeProofread(api);
      });
    }
  };
});